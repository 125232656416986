<template>
  <div class="row">
    <div class="col-12 col-lg-8 c g">
      <div class="card">
        <div class="card-header">
          التقارير المحفوظة على هذا الجهاز "{{ device.title }}"
          <div class="text-center">
            <button ref="uploadBTN" class="btn btn-success" @click="upload()">
              رفع التقارير
            </button>
            <br /><br />
            <div class="form-group">
              <label for="">التاريخ المراد رفع تقاريره</label>
              <input
                type="date"
                :value="new Date().toISOString().split('T')[0]"
                class="form-control"
                ref="day"
              />
            </div>
          </div>
          <br />
          <button class="btn btn-outline-danger" @click="clear()">
            حذف البصمات من الجهاز
          </button>
        </div>
        <input type="hidden" ref="ii" />
        <div class="card-body">
          <div class="col-12 text-center" v-if="loading">
            <img
              src="../assets/images/loading.svg"
              style="width: 60px; height: 60px"
              alt=""
            />
            جاري تحميل البصمات..
          </div>
          <div class="col-12 table-responsive">
            <table class="table table-hover table-striped">
              <thead>
                <th>المعلم</th>
                <th>التوقيت</th>
              </thead>
              <tbody>
                <tr v-for="report in reports" v-bind:key="report.k">
                  <td>
                    {{ report.name }}
                    <br />
                    <small>{{ report.number }}</small>
                  </td>
                  <td>
                    {{ report.time }}
                    <br />
                    <small>منذ {{ report.timeago }}</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            * يتم تحميل البصمات كل فترة اثناء ترك هذه الصفحة مفتوحة *
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
import moment from "moment-timezone";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      device: {},
      reports: [],
      teachers: [],
      loading: true,
    };
  },
  methods: {
    upload() {
      var _g = this;
      var html = this.$refs.uploadBTN.innerHTML;
      this.$refs.uploadBTN.innerHTML = `<img
              src="https://cdn-static.brmja.com/storage/scrapped/61e456bca5d25891458442.svg"
              style="width: 40px; height: 40px"
              alt=""
            /> جاري الرفع..`;
      axios
        .get(localStorage.getItem("fp") + `/attends/${this.device.ip}/4370`)
        .then(function (r) {
          var arr = [];
          _g.loading = false;
          r.data.forEach(function (a) {
            var teacher = false;
            _g.teachers.forEach(function (t) {
              if (a.deviceUserId == t.number) {
                teacher = t;
              }
            });
            if (
              teacher != false &&
              _g.$refs.day.value == a.recordTime.split("T")[0]
            ) {
              arr.push({
                time: a.recordTime,
                teacher: teacher,
                number: a.deviceUserId,
              });
            }
          });
          axios
            .post(localStorage.getItem("api") + "/teachers/upload-fp", {
              jwt: localStorage.getItem("jwt"),
              arr: arr,
            })
            .then(function (r) {
              if (r.data.status == 100) {
                axios
                  .post(localStorage.getItem("api") + "/teachers/upload", {
                    jwt: localStorage.getItem("jwt"),
                    arr: r.data.arr,
                  })
                  .then(function (r) {
                    if (r.data.status == 100) {
                      _g.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "تم الرفع بنجاح",
                          icon: "CheckIcon",
                          variant: "success",
                        },
                      });
                      _g.$refs.uploadBTN.innerHTML = html;
                    } else {
                      _g.loading = false;
                      _g.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "حدث خطأ اثناء الرفع 2",
                          icon: "WarningIcon",
                          variant: "danger",
                        },
                      });
                    }
                  })
                  .catch(function (e) {
                    console.log(e);
                    _g.loading = false;
                    _g.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "حدث خطأ اثناء الرفع",
                        icon: "WarningIcon",
                        variant: "danger",
                      },
                    });
                  });
              } else {
                alert("هناك خطأ");
              }
            })
            .catch(function () {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ عند محاولة ارسال البصمات",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
              _g.$refs.uploadBTN.innerHTML = html;
            });
        })
        .catch(function () {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ عند محاولة الاتصال بجهاز البصمة",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
          _g.$refs.uploadBTN.innerHTML = html;
        });
    },
    clear() {
      var _g = this;
      if (confirm("متأكد من حذف جميع البصمات الموجودة على جهاز البصمة؟")) {
        if (confirm("هل انت متأكد من رفع جميع التقارير سابقاً؟")) {
          axios
            .get(
              localStorage.getItem("fp") +
                `/clear/${this.device.ip}/4370/11/` +
                window.location.pathname.split("/device/")[1]
            )
            .then(function (a) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم حذف البصمات من الجهاز",
                  icon: "WarningIcon",
                  variant: "success",
                },
              });
              location.reload();
            })
            .catch(function () {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تعذر الاتصال بالبرنامج",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    },
    getAttends() {
      var _g = this;
      _g.loading = true;
      if (this.$refs.ii != undefined) {
        axios
          .get(localStorage.getItem("fp") + `/attends/${this.device.ip}/4370`)
          .then(function (r) {
            var arr = [];
            _g.loading = false;
            r.data.forEach(function (a) {
              var name = null;
              _g.teachers.forEach(function (t) {
                if (a.deviceUserId == t.number) {
                  name = t.name;
                }
              });
              arr.push({
                time: a.recordTime,
                name: name,
                number: a.deviceUserId,
                timeago: moment(a.recordTime)
                  .fromNow(true)
                  .replace("hours", "ساعات")
                  .replace("minutes", "دقائق")
                  .replace("minute", "دقيقة")
                  .replace("hour", "ساعة")
                  .replace("day", "يوم")
                  .replace("days", "ايام")
                  .replace("second", "ثانية")
                  .replace("seconds", "ثوان")
                  .replace("ago", "مضت"),
              });
            });
            _g.reports = arr;
            setTimeout(() => {
              _g.getAttends();
            }, 5000);
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ عند محاولة الاتصال بجهاز البصمة",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
            setTimeout(() => {
              _g.getAttends();
            }, 3000);
          });
      }
    },
  },
  created() {
    moment.tz.setDefault("Asia/Riyadh");
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/list", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.teachers = r.data.response;
          axios
            .post(localStorage.getItem("api0") + "/devices/device", {
              id: window.location.pathname.split("/device/")[1],
              jwt: localStorage.getItem("jwt"),
            })
            .then(function (r) {
              _g.device = r.data;
            });
          var i = setInterval(() => {
            if (_g.$refs.ii != undefined) {
              clearInterval(i);
              _g.getAttends();
            }
          }, 1000);
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
